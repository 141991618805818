import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-footer" }
const _hoisted_3 = { class: "d-flex justify-content-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vc_user_form = _resolveComponent("vc-user-form")!
  const _component_vc_button_navigator = _resolveComponent("vc-button-navigator")!
  const _component_vc_button_save = _resolveComponent("vc-button-save")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createBlock(_component_el_form, {
    ref: "ruleFormRef",
    model: _ctx.user,
    "status-icon": "",
    rules: _ctx.rules,
    "scroll-to-error": "",
    id: "UserForm",
    onSubmit: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.submitStore(_ctx.ruleFormRef)), ["prevent"]))
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_vc_user_form, {
          ref: "userForm",
          firstError: _ctx.firstError
        }, null, 8, ["firstError"]),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_vc_button_navigator, { value: "UserLists" }),
            _createVNode(_component_vc_button_save, { loading: _ctx.loading }, null, 8, ["loading"])
          ])
        ])
      ])
    ]),
    _: 1
  }, 8, ["model", "rules"]))
}